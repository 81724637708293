@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "DynaPuff", system-ui;
  scroll-behavior: smooth;
}

body {
  @apply bg-gold
}

section {
  @apply shadow shadow-black;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#intro {
  background-image: url('./images/intro-bg.webp');
  background-position: bottom;
}

#what-is {
  background-image: url('./images/what-is-bg.jpg');
}

#where-to-buy {
  background-image: url('./images/where-to-buy-bg.jpg');
  @apply bg-fixed;
}

#photo-gallery {
  background-image: url('./images/photo-gallery-bg.jpg');
  @apply bg-fixed;
}

#socials {
  background-image: url('./images/socials-bg.jpg');
}

// dex
#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}

@media(min-width:1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}

#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}